/* You can add global styles to this file, and also import other style files */
/*@import "~bootstrap/dist/css/bootstrap.css";

body{
    background-color: #f7f7f7 !important;
}

.margin-top-70{
    margin-top: 70px;
}*/
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~ngx-toastr/toastr';

:host ::ng-deep .email-fixed-header {
  min-height: calc(100% - 207px)!important;
  margin-top: 0!important;
  overflow-y: hidden !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-teal {
  color: #fff;
  background-color: #20c997;
  border-color: #20c997;
}

.btn-darkred {
  background-color: #FF0000;
  border-color: #FF0000;
  color: #fff;
}

.no-cursor:hover {
  cursor: none;
}

.height-10 {
  height: 10px !important;
}

.height-5 {
  height: 5px !important;
}

.code {
    padding: 0.2rem 0.4rem;
}

.curser-pointer:hover {
  cursor: pointer;
}